import { keyPrefix } from 'providers/QueryProvider';

/**
 * Helper function to add an item to an array if it is defined
 * Can be beneficial for invalidating caches where you don't have access to the specifics of it.
 */
const addIfDefined = <T>(item: T | undefined): T[] => {
  return item !== undefined ? [item] : [];
};

const accountKeys = {
  accountList: keyPrefix(['account-list']),
  accountListWithoutBalances: keyPrefix(['account-list', 'without-balances']),
  accountListBalances: keyPrefix(['account-list', 'balances']),
  accountCreateV1: keyPrefix(['account-create-v1']),
  accountCreateV2: keyPrefix(['account-create-v2']),
  accountRemove: keyPrefix(['account-remove']),
  accountUpdate: keyPrefix(['account-update']),
  accountSelect: keyPrefix(['account-select']),
  accountRead: (accountId: string) => keyPrefix(['account-read', accountId]),
};

const addressKeys = {
  isSmartContract: (address: string, chainId: number) =>
    keyPrefix(['is-smart-contract', address, chainId]),
  isSafe: (address: string, chainId: number) =>
    keyPrefix(['is-safe', address, chainId]),
};

const orderKeys = {
  orderList: (accountId: string) => keyPrefix(['order-list', accountId]),
  orderCreate: keyPrefix(['order-create']),
  orderRead: (orderId: string) => keyPrefix(['order-read', orderId]),
};

const signatureKeys = {
  pendingOrderSignatures: (address?: string) =>
    keyPrefix(['pending-signatures', ...addIfDefined(address)]),
};

const treasuryAccountKeys = {
  treasuryList: keyPrefix(['treasury-list']),
  treasuryCreate: keyPrefix(['treasury-create']),
  treasuryUpdate: keyPrefix(['treasury-update']),
  treasuryRead: (accountId: string) => keyPrefix(['treasury-read', accountId]),
};

/**
 * React query cache keys
 */
export const getKeys = {
  ...addressKeys,
  ...accountKeys,
  ...orderKeys,
  ...signatureKeys,
  ...treasuryAccountKeys,
};
